import React, {useState, useEffect} from 'react';

import {Modal, Accordion, Button, Table} from 'react-bootstrap';


const UserSettings = (props) => {

  const formatResponse = (value) => {
    try {
      if (props.user&&props.user[value]){
        return props.user&&props.user[value];
      }
      return "N/A";
    } catch {
      return "N/A"
    }
  }

  const formatResponseAccounts = (item, value) => {
    try {
      if (item&&item[value]){
        return item&&item[value];
      }
      return "N/A";
    } catch {
      return "N/A"
    }
  }

  return (<>
      <Modal show={props.show} onHide={props.handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{props.user.first_name} {' '} {props.user.middle_name} {' '} {props.user.last_name} Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5><b>{"Personal Information"}</b></h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Field</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              {props.user&&Object.keys(props.user).map(item => {
                if (["first_name", "last_name", "email", "phone", "address", "next_of_kin_name", "next_of_kin_phone"].includes(item)){
                  return (
                    <tr><td>{`${item.split("_").join(" ").toUpperCase()}`}</td><td>{`${formatResponse(item)}`}</td></tr>
                  )
                }
              })}
            </tbody>
          </Table><br/>
          <h5><b>{"Account Information"}</b></h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Field</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              {props.user&&props.user.accounts&&props.user.accounts.map(item => {
                Object.keys(item).map(itm => {
                  return (
                    <tr><td>{`${itm.split("_").join(" ").toUpperCase()}`}</td><td>{`${formatResponseAccounts(item, itm)}`}</td></tr>
                  )
                })
              })}
            </tbody>
          </Table>
          <h5><b>{"Verification Information"}</b></h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Field</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              {props.user&&Object.keys(props.user).map(item => {
                if (["kyc_doc_front", "kyc_doc_number", "kyc_doc_type", "kyc_verified", "kyc_doc_issue_date", "kyc_doc_expiry_date"].includes(item)){
                  return (
                    <tr><td>{`${item.split("_").join(" ").toUpperCase()}`}</td><td>{`${formatResponse(item)}`}</td></tr>
                  )
                }
              })}
            </tbody>
          </Table>

          {/* {props.user&&Object.keys(props.user).map(item => {
            if (!["otp", "otp_expiry", "id", "is_premium", "clubs", "user_subinvestor", "accounts", "completed_onboarding", "completed_first_investment", "is_superuser", "is_staff", "is_axamansard", "gbp", "display_gbp", "usd", "display_usd", "qualification_cost"].includes(item)){
              return (
                <p>{`${item.split("_").join(" ").toUpperCase()}: ${formatResponse(item)}`}</p>
              )
            }
          })} */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  </>)
}

export default UserSettings;
